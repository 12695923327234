@import '../index.scss';
@import '../styles/globalCssVariables.scss';

.headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--headerBackground);
  color: #ffffff;
  margin: 0 -50px;
  padding: 0 60px;

  .headline_block {
    padding: 20px 0;
  }

  h2 {
    font-weight: normal;
    padding-left: 20px;
  }
}

.headline__logo {
  height: 30px;
  padding-left: 20px;
}

.notification {
  position: relative;
}

.notification_icon {
  cursor: pointer;
}

.notification_list {
  position: absolute;
  margin-top: 0px;
  margin-left: -100px;
  background-color: #ffffff;
  width: 300px;
  z-index: 10;
  border: 1px solid #dddddd;
  color: #333333;
  font-size: 14px;
  padding: 20px;
  transition: all 0.3s ease;
  visibility: hidden;
  opacity: 0;

  &:hover {
    visibility: visible;
    opacity: 1;
  }
}

.notification_icon:hover + .notification_list {
  visibility: visible;
  opacity: 1;
}

.notifications {
  max-height: 600px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.notification_list_block {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dddddd;
  width: 100%;

  span {
    font-size: 12px;
    opacity: 0.8;
  }
}

.all_notifications {
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.6;
  }
}

.notification_readed {
  width: 10px;
  height: 10px;
  background-color: $lightBlue;
  cursor: pointer;
  border-radius: 50px;
}

@media only screen and (max-width: 450px) {
  .headline {
    justify-content: center;
  }
}
