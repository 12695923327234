@import '../styles/globalCssVariables.scss';

html {
  --inputBackgroundColor: #e6f4fd;
  --inputTextColor: #333333;
}

html .darkTheme {
  --inputBackgroundColor: #303030;
  --inputTextColor: #cccccc;
}

.picker {
  position: relative;
  z-index: 100 !important;

  .MuiInputBase-input {
    display: none;
  }

  .react-date-picker {
    padding: 5px;
    width: 100%;
    input {
      color: var(--inputTextColor);
    }
  }

  .react-date-picker__wrapper {
    border: none;
    width: 100%;
    background-color: var(--inputBackgroundColor);
  }

  .react-calendar__month-view__weekNumbers {
    color: $electric;
  }
}
