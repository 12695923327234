@import '../../../styles/globalCssVariables.scss';

html {
  --backgroundColor: #f6f6f6;
  --borderColor: #dddddd;
}

html .darkTheme {
  --backgroundColor: #6f6f6f;
  --borderColor: #e6f4fd;
}

.evaluationPreview {
  h3 {
    color: $electric;
    font-weight: 400;
  }
  .evaluationPreview_details {
    display: flex;
    margin: 5px 0 20px 0;
    font-size: 14px;

    div {
      margin-right: 20px;
    }
  }
}

.evaluationPreview_evaluation {
  display: flex;

  .agentBlock {
    padding: 20px;
    cursor: pointer;
    width: 250px;
    transition: all 0.2s ease;

    div {
      font-size: 14px;
      opacity: 0.7;
    }
  }

  .agentBlock__active {
    @extend .agentBlock;
    border-right: unset;
    width: 251px;
    margin-right: -1px;
    background-color: var(--backgroundColor);
    z-index: 10;
  }

  .evaluationBlock {
    display: none;
    top: 20px;
    padding: 20px;
    left: -webkit-calc(250px + 20px);
    width: 100%;
    background-color: var(--backgroundColor);

    .evaluationContainer {
      margin-bottom: 30px;
      padding-bottom: 20px;
      border-bottom: 1px solid #dddddd;

      &:last-child {
        padding-bottom: 0;
        border-bottom: unset;
      }
    }

    .evaluationHead {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  .evaluationBlock__active {
    @extend .evaluationBlock;
    display: block;
  }
}
