@import '../index.scss';


.openMenu {
    position: fixed;
    left: -1px;
    top: 50px;
    cursor: pointer;
    border: 1px solid var(--openMenuShadow);
    border-radius: 0 $borderRadius  $borderRadius 0;
    background-color: var(--background);
    color: var(--textColor);
    z-index: 1000;
    box-shadow: 0 0 7px $grey;

    div {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        span {
            margin-right: 5px;
            -webkit-transform:rotate(90deg);
            -moz-transform:rotate(-90deg);
            -o-transform: rotate(-90deg);
            -ms-transform:rotate(-90deg);
            transform: rotate(-90deg);
            margin: 10px 0;
        }
    }
}

.openMenu__icon {
    color: $orange;
    margin: 10px;
}