// Colors
$electric: rgb(71, 157, 186);
$grey: rgb(168, 168, 168);
$grey9: #d6d6d6;
$grey10: #e4e4e4;
$grey11: #e0e0e0;
$inputColor: #e6f4fd;
$darkBlue: #215384;
$lightBlue: #43688e;
$green1: #618b25;
$orange: #e03b22;

// Employee status colors
$statusPlanned: #f6ae2d;
$statusAvailable: #b95f89;
$statusAtHome: #b85137;
$statusAtWork: #618b25;
$statusMedicine: #ee6c4d;
$statusCourses: #2f4856;

$borderRadius: 2px;
