@import '../../../styles/globalCssVariables.scss';

.notifications_page {
  .notification_readed {
    width: 10px;
    height: 10px;
    background-color: $lightBlue;
    cursor: pointer;
    border-radius: 50px;
    transition: all 0.3s ease;

    &:hover {
      border: 2px solid $lightBlue;
      background-color: #ffffff;
    }
  }

  .notification_readed__not {
    @extend .notification_readed;
    border: 2px solid $lightBlue;
    background-color: #ffffff;

    &:hover {
      background-color: $lightBlue;
    }
  }
}
