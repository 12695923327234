@import '../../styles/globalCssVariables.scss';

.vessels_counters {
  opacity: 0.8;
  font-size: 12px;
  margin-top: 10px;

  .count {
    display: inline-block;
    margin-right: 20px;
  }
}
