@import '../../globalCssVariables.scss';

.jobAd {
  .jobTitle {
    color: $electric;
    font-size: 24px;
  }

  .details {
    margin: 40px 0 50px -10px;
  }

  h3 {
    margin: 30px 0 10px 0;
    color: $lightBlue;
    font-weight: 400;
  }
}

.jobAgent {
  .agentTitle {
    text-align: center;
    color: $electric;
    font-weight: 400;
    font-size: 22px;
    margin: 0 auto 40px;
  }

  .agencyLogo {
    margin: 0 auto 50px auto;

    img {
      max-width: 200px;
      max-height: 50px;
    }
  }

  h4 {
    text-align: center;
    margin-bottom: 10px;
    opacity: 0.9;
  }

  h3 {
    text-align: center;
    color: $darkBlue;
  }

  .jobContacts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;

    .block {
      margin: 5px 10px;

      .jobContacts_icon {
        text-align: center;
        font-size: 20px;
        margin-bottom: 5px;
        color: $lightBlue;
        opacity: 0.9;
      }
    }
  }

  .jobAgent_buttons {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    margin: 40px auto 0;
  }
}

.jobAd_login {
  h3 {
    text-align: center;
    margin-bottom: 30px;
    color: $electric;
  }
}

.jobAd_passwordReset {
  text-align: center;
  font-size: 14px;
}
