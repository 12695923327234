@import '../../../../styles/globalCssVariables.scss';

.preview {
  width: 500px;
  padding: 30px;

  .close {
    cursor: pointer;
    width: fit-content;
    transition: all 0.3s ease;
    margin-bottom: 20px;
    font-size: 18px;

    &:hover {
      opacity: 0.8;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .status {
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 2px solid $electric;
      border-radius: $borderRadius;
      padding: 5px 10px;
      margin: 10px;
      text-align: center;
      font-size: 14px;
      width: 20%;

      span {
        display: block;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px 0;
  }
}
