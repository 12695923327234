@import '../../../../styles/globalCssVariables.scss';

html {
  --backgroundColor: #f6f6f6;
  --borderColor: #dddddd;
}

html .darkTheme {
  --backgroundColor: #6f6f6f;
  --borderColor: #e6f4fd;
}

.profilePhoto {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  background-color: $electric;

  img {
    width: 100%;
    height: 100%;
  }

  .icon {
    color: #ffffff;
    height: 80%;
    margin-top: 20px;
  }
}

.task_icons {
  display: flex;
  justify-content: flex-end;

  .task_icon {
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }
}

.evaluation {
  display: flex;

  .agentBlock {
    padding: 20px;
    cursor: pointer;
    width: 250px;
    transition: all 0.2s ease;

    div {
      font-size: 14px;
      opacity: 0.7;
    }
  }

  .agentBlock__active {
    @extend .agentBlock;
    border-right: unset;
    width: 251px;
    margin-right: -1px;
    background-color: var(--backgroundColor);
    z-index: 10;
  }

  .evaluationBlock {
    display: none;
    top: 20px;
    padding: 20px;
    left: -webkit-calc(250px + 20px);
    width: 800px;
    background-color: var(--backgroundColor);

    .evaluationContainer {
      margin-bottom: 30px;
    }

    .evaluationHead {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  .evaluationBlock__active {
    @extend .evaluationBlock;
    display: block;
  }
}
