@import '../index.scss';

.footer {
    flex-shrink: 0;
    width: 100%;
    height: 50px;
    background-color: var(--footerBackground);
    color: #ffffff;
    margin-top: 20px;
}

.footer_content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    padding: 0 50px;
}

.footer__version {
    color: $lightGrey;
    font-size: 14px;
    cursor: pointer;
}