@import '../../styles/globalCssVariables.scss';

$hoverOpacity: 0.5;
$transition: all 0.3s ease;
$elementsMarginRight: 20px;
$backgroundColor: $statusCourses;
$textColor: rgb(104, 189, 217);

.navigation {
  position: absolute;
  display: flex;
  align-items: center;
  right: 10px;
  bottom: 20px;
  padding: 10px 10px;
  background-color: $backgroundColor;
  border-radius: 8px;
  color: $textColor;
  font-weight: 400;
  font-size: 14px;
}

.arrows {
  display: flex;
  margin-right: $elementsMarginRight;
  div {
    cursor: pointer;
    transition: $transition;

    &:hover {
      opacity: $hoverOpacity;
    }

    &:first-child {
      margin-right: 10px;
    }
  }
}

.period {
  margin-right: $elementsMarginRight;
}

.menuButton {
  cursor: pointer;
  text-transform: capitalize;
  transition: $transition;

  &:hover {
    opacity: $hoverOpacity;
  }
}

.menu {
  position: absolute;
  bottom: 38px;
  right: 60px;
  padding: 10px;
  z-index: 10;
  background-color: $backgroundColor;
  border-radius: 5px;
  transition: $transition;
  visibility: visible;
  opacity: 1;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      cursor: pointer;
      margin-bottom: 5px;
      text-transform: capitalize;

      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        opacity: $hoverOpacity;
      }
    }
  }
}

.menu__hide {
  @extend .menu;
  visibility: hidden;
  opacity: 0;
}

.datePicker {
  .date {
    cursor: pointer;
    transition: $transition;
    margin-right: $elementsMarginRight;

    &:hover {
      opacity: $hoverOpacity;
    }
  }

  .react-datepicker {
    border: unset;
    background-color: $backgroundColor;
    color: $textColor;
    border-radius: 5px;
  }

  .react-datepicker__header,
  .react-datepicker-year-header {
    background-color: $backgroundColor;
    color: $textColor;
    border-bottom: unset;
  }

  .react-datepicker__navigation-icon::before {
    border-color: $textColor;
  }

  .react-datepicker__month .react-datepicker__month-text {
    width: 3rem;
    transition: $transition;
  }

  .react-datepicker__month-text:hover {
    background-color: rgba(104, 189, 217, 0.1);
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: $electric;
    border-radius: 5px;
  }

  .react-datepicker-popper[data-placement='top-end']
    .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation *::before {
    transition: $transition;
  }
  .react-datepicker__navigation:hover *::before {
    border-color: $textColor;
    opacity: $hoverOpacity;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    display: none;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    display: none;
  }
}

.reset {
  font-size: 13px;
  cursor: pointer;
  transition: $transition;

  &:hover {
    opacity: $hoverOpacity;
  }
}
