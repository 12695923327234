@import '../../styles/globalCssVariables.scss';

.addProjects_icon {
  margin-left: 10px;
  cursor: pointer;
  color: $orange;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}

.company_counters {
  opacity: 0.8;
  font-size: 12px;
  margin-top: 10px;

  .count {
    display: inline-block;
    margin-right: 20px;
  }
}

.conpanyPage_project_buttons {
  display: flex;

  .icon_button {
    margin-left: 15px;
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.3 ease;

    &:hover {
      opacity: 1;
    }
  }
}
