@import '../../globalCssVariables.scss';

.agencyLogo {
  display: flex;
  justify-content: center;
  margin: 50px 50px 0 50px;

  img {
    width: auto;
    max-width: 300px;
    max-height: 100px;
  }
}

.jobTitle {
  font-size: 20px;
  color: $electric;
  margin-bottom: 10px;
}

.jobDetails {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  opacity: 0.8;
  margin-bottom: 20px;

  div {
    margin-bottom: 5px;
    margin-right: 20px;
  }
}

.jobDescription {
  margin-bottom: 40px;
}
