@import '../index.scss';

.button {
    width: fit-content;
    padding: 8px 16px;
    background-color: $orange;
    color: #ffffff;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: $borderRadius;
}
.button:hover {
    background-color: $orangeDark;
}

.button-disable {
    width: fit-content;
    padding: 8px 16px;
    background-color: $lightGrey;
    color: #ffffff;
    text-transform: capitalize;
    border-radius: $borderRadius;
}