@import '../../globalCssVariables.scss';

html {
  --calendarBackgroundColor: #e4e4e4;
  --weekendBackgroundColor: #e0e0e0;
  --counterTextColor: #333333;
  --toggleButtonTextColor: #333333;
  --toggleButtonBorderColor: #333333;
  --toggleButtonBackgroundColor: #33333320;
  --printerIconColor: #333333;
}

html .darkTheme {
  --calendarBackgroundColor: #6f6f6f;
  --weekendBackgroundColor: #616161;
  --counterTextColor: #cccccc;
  --toggleButtonTextColor: #cccccc;
  --toggleButtonBorderColor: #cccccc;
  --toggleButtonBackgroundColor: #ffffff20;
  --printerIconColor: #cccccc;
}

.job__textReader {
  ol,
  ul {
    padding-left: 30px;
  }
}

.link {
  display: flex;
  justify-content: center;
  margin: 25px;
  text-align: center;

  .copy {
    margin-left: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

$calenderMinHeight: 400px;

.calendar {
  position: relative;
  width: fit-content;
  margin: auto;
  width: 100%;
  max-width: 1400px;
}

.calendar_container {
  position: relative;
  display: flex;
  align-items: stretch;
  background-color: var(--calendarBackgroundColor);
  height: 100%;
  width: 100%;
  max-width: 1400px;
  min-height: $calenderMinHeight;
  overflow-x: scroll;
  border-radius: $borderRadius;
  cursor: auto;
}

.calendar_month {
  width: fit-content;

  h5 {
    padding: 10px 0;
    font-weight: 400;
    text-align: center;
    border-right: 1px solid $grey9;
  }
}

.calendar_month__week {
  @extend .calendar_month;
  width: 100%;
}

.calendar_days {
  display: flex;
  width: 100%;
  border-top: 1px solid $grey9;
}

.calendar_day {
  position: relative;
  min-width: 200px;
  width: 100%;
  min-height: $calenderMinHeight;
  border-right: 1px solid $grey9;

  .week_number {
    position: absolute;
    left: 5px;
    top: -17px;
    font-size: 13px;
    font-weight: 600;
  }
}

.calendar_day__monday {
  @extend .calendar_day;
  border-left: 5px solid $grey9;
}

.calendar_day__weekend {
  @extend .calendar_day;
  background-color: var(--weekendBackgroundColor);
}

.calendar_header {
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid $grey9;
  font-weight: 600;
  font-size: 14px;
}

.calendar_job {
  min-height: 100px;
  background-color: $lightBlue;
  border-radius: $borderRadius;
  margin: 5px 2px;
  cursor: pointer;
  padding: 10px;
  color: #ffffff;
  font-size: 12px;

  &:last-child {
    margin-bottom: 50px;
  }

  .title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    opacity: 0.7;
    div {
      margin: 0 10px 5px 0;
    }
  }

  .assigned {
    opacity: 0.9;
    span {
      margin-right: 10px;
    }
  }
}

.calendar_job:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.calendar_job__closed {
  @extend .calendar_job;
  border: 2px solid $lightBlue;
  background-color: unset;
  color: unset;
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  background-color: #cfcfcf;
  padding: 5px;
  border: 1px solid $electric;
  cursor: default;
  color: #333333;
}

.counter__progress {
  @extend .counter;
  background-color: $electric;
  color: #ffffff;
}

.counter__closed {
  @extend .counter;
  background-color: unset;
  color: var(--counterTextColor);
}

.MuiToggleButton-root {
  color: var(--toggleButtonTextColor) !important;
  border-color: var(--toggleButtonBorderColor) !important;
  &.Mui-selected {
    background-color: var(--toggleButtonBackgroundColor) !important;
  }
}

.print {
  cursor: pointer;
  margin: 0 20px;
  font-size: 18px;
  color: var(--printerIconColor);
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}
