@import '../../../../../styles/globalCssVariables.scss';

.icon {
  font-size: 14px;
  opacity: 0.7;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
  }
}
