@import '../index.scss';

.loader {
	position: fixed;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	left: 0;
	background-color: rgba($color: #ffffff, $alpha: 0.7);
	z-index: 10000;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loader_spinner {
	border: 4px solid $lightBlue;
	border-radius: 50%;
	border-top: 4px solid $orange;
	width: 50px;
	height: 50px;
	-webkit-animation: spin 0.5s linear infinite;
	animation: spin 0.5s linear infinite;
}
