@import '../../../../styles/globalCssVariables.scss';

html {
  --actionBackgroundColor: #e4e4e4;
  --blockBackgroundColor: #f7f7f7;
  --blockHeaderTextColor: rgb(71, 157, 186);
}

html .darkTheme {
  --actionBackgroundColor: #6f6f6f;
  --blockBackgroundColor: #8b8b8b;
  --blockHeaderTextColor: #cccccc;
}

.management_container {
  max-width: 1300px;
  margin: auto;
  padding: 50px;
}

.ad_status {
  display: flex;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid $electric;
    border-radius: $borderRadius;
    padding: 5px 10px;
    margin: 10px;
    text-align: center;
    font-size: 14px;
    width: 150px;

    span {
      display: block;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.application_action {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 300px;
  background-color: var(--actionBackgroundColor);
  border-radius: $borderRadius;

  .block {
    width: 100%;
    background-color: var(--blockBackgroundColor);
    margin: 10px;
    border-radius: $borderRadius;

    .headerLine {
      padding: 10px;
      border-bottom: 1px solid var(--actionBackgroundColor);
      color: var(--blockHeaderTextColor);
    }
  }
}

.candidate {
  padding: 10px;
  background-color: $lightBlue;
  margin: 10px;
  border-radius: $borderRadius;
  color: #ffffff;
  cursor: pointer;

  .rating {
    display: flex;
    justify-content: flex-end;
  }

  .candidate_header {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .profilePhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    background-color: $electric;

    img {
      width: 100%;
      height: 100%;
    }

    .icon {
      color: #ffffff;
      height: 80%;
      margin-top: 10px;
    }
  }

  .name {
    text-transform: capitalize;
    margin-left: 10px;
  }

  .status {
    display: flex;
    margin: 10px 0;
    font-size: 14px;
    opacity: 0.8;

    div {
      margin-right: 10px;
    }
  }

  &:last-child {
    margin-bottom: 50px;
  }
}

.candidate__closed {
  @extend .candidate;
  background-color: unset;
  border: 2px solid $lightBlue;
  color: unset;
}
