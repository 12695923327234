html {
  --loaderColor: #000000;
}

html .darkTheme {
  --loaderColor: #ffffff;
}

.fileUploadLoader {
  display: flex;
  align-items: center;

  .loader {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    background-color: transparent;

    div {
      position: absolute;
      top: 12px;
      left: 12px;
      transform-origin: 0px 0px;
      -webkit-animation: loader 0.8s linear infinite;
      animation: loader 0.8s linear infinite;
    }

    div:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 6px;
      left: 0px;
      width: 2px;
      height: 4px;
      border-radius: 50%;
      background: var(--loaderColor);
    }

    div:nth-child(1) {
      transform: rotate(0deg);
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s;
    }

    div:nth-child(2) {
      transform: rotate(45deg);
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;
    }

    div:nth-child(3) {
      transform: rotate(90deg);
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }
    div:nth-child(4) {
      transform: rotate(135deg);
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
    }
    div:nth-child(5) {
      transform: rotate(180deg);
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }
    div:nth-child(6) {
      transform: rotate(225deg);
      -webkit-animation-delay: -0.2s;
      animation-delay: -0.2s;
    }
    div:nth-child(7) {
      transform: rotate(270deg);
      -webkit-animation-delay: -0.1s;
      animation-delay: -0.1s;
    }
    div:nth-child(8) {
      transform: rotate(315deg);
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
  }

  @-webkit-keyframes loader {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes loader {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  span {
    margin-left: 10px;
  }
}
