@import '../src/styles/globalCssVariables.scss';

//Fonts
@font-face {
  font-family: Roboto;
  src: url(./media/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto;
  src: url(./media/fonts/Roboto-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url(./media/fonts/Roboto-Italic.ttf);
  font-style: italic;
}
//Color variables
// New colors
$grey: rgb(168, 168, 168);

// Old colors
$orange: #e03b22;
$orangeDark: #d6371f;
$lightGrey: #737372;
$errorColor: rgb(247, 37, 37);
$green: #78a10a;
$yellow: #f0c808;

:root {
  --background: #f0f0f0;
  --darkGrey: #333333;
  --electric: rgb(71, 157, 186);
  --orange: #e03b22;
  --darkBlue: #215384;
  --lightBlue: #43688e;
  --inputColor: #e6f4fd;
  --grey: rgb(168, 168, 168);
  --lightGrey: #737372;
  --grey6: rgba(0, 0, 0, 0.23);
  --grey8: rgba(0, 0, 0, 0.54);
  --statusPlanned: #f6ae2d;
  --statusAvailable: #b95f89;
  --statusAtHome: #b85137;
  --statusAtWork: #618b25;
  --statusMedicine: #ee6c4d;
  --statusCourses: #2f4856;

  --textColor: var(--darkGrey);
  --paperBackground: #ffffff;

  --cardHeaderBorderBottom: #e03b22;
  --cardHeaderBackground: var(--background);
  --cardHeaderTitle: var(--darkBlue);
  --cardHeaderBorder: #d6d6d6;

  --headerBackground: var(--electric);
  --footerBackground: var(--darkGrey);

  --openMenuShadow: var(--darkBlue);

  --inputBackground: var(--inputColor);
  --inputLabelColor: var(--grey);
  --inputBorderColor: var(--grey6);
  --inputTextColor: var(--textColor);
  --inputSelectionTextColor: var(--textColor);
  --datePickerIconColor: invert(0%);
  --helperTextColor: var(--grey8);

  --buttonTransparentTextColor: var(--lightGrey);

  --accordionBorderColor: var(--darkBlue);

  --stepperLabelTextColor: var(--grey8);
  --stepperLabelActiveTextColor: var(--textColor);
  --stepperIconBackgroundColor: var(--darkBlue);

  --photoBoxColor: var(--darkBlue);

  --tabTextColor: var(--lightBlue);

  --typographyColorTextSecondary: var(--grey8);

  --chipOutlineBorderColor: var(--grey6);

  --dialogHeaderBackgroundColor: var(--electric);
  --dialogHeaderTextColor: #ffffff;

  --tableStickyHeaderTextColor: var(--textColor);

  --rechartTextColor: var(--darkBlue);

  --appBarBackgroundColor: var(--headerBackground);

  --switchIconsColor: #ffffff;

  --documentSectionBackground: var(--inputBackground);
  --documentSectionTextColor: var(--darkBlue);

  --chipBackgroudColor: rgba(0, 0, 0, 0.08);
}

html .darkTheme {
  --background: #303030;
  --veryLightGrey: #cccccc;
  --darkGrey: #333333;
  --orange: #e03b22;
  --electric: rgb(71, 157, 186);
  --grey1: rgba(255, 255, 255, 0.08);
  --grey2: rgba(255, 255, 255, 0.7);
  --grey3: rgba(255, 255, 255, 0.12);
  --grey4: rgba(255, 255, 255, 0.16);
  --grey5: rgba(255, 255, 255, 0.8);
  --grey6: rgba(0, 0, 0, 0.23);
  --grey7: rgba(255, 255, 255, 0.3);
  --statusPlanned: #f6ae2d;
  --statusAvailable: #b95f89;
  --statusAtHome: #b85137;
  --statusAtWork: #618b25;
  --statusMedicine: #ee6c4d;
  --statusCourses: #2f4856;

  --textColor: var(--veryLightGrey);
  --paperBackground: #424242;

  --cardHeaderBorderBottom: var(--electric);
  --cardHeaderBackground: var(--grey1);
  --cardHeaderTitle: var(--textColor);
  --cardHeaderBorder: var(--grey3);

  --headerBackground: var(--electric);
  // --headerBackground: var(--grey4);
  --footerBackground: var(--grey4);

  --openMenuShadow: var(--grey1);

  --inputBackground: var(--background);
  --inputLabelColor: var(--grey7);
  --inputBorderColor: var(--grey2);
  --inputTextColor: var(--textColor);
  --inputSelectionTextColor: var(--textColor);
  --datePickerIconColor: invert(70%);
  --helperTextColor: var(--grey7);

  --buttonTransparentTextColor: var(--grey2);

  --accordionBorderColor: var(--grey2);

  --stepperLabelTextColor: var(--grey7);
  --stepperLabelActiveTextColor: var(--textColor);
  --stepperIconBackgroundColor: var(--electric);

  --photoBoxColor: var(--grey7);

  --tabTextColor: var(--grey2);

  --typographyColorTextSecondary: var(--grey7);

  --chipOutlineBorderColor: var(--grey2);

  --dialogHeaderBackgroundColor: var(--headerBackground);
  --dialogHeaderTextColor: var(--textColor);

  --tableStickyHeaderTextColor: var(--darkGrey);

  --rechartTextColor: var(--textColor);

  --appBarBackgroundColor: var(--headerBackground);

  --switchIconsColor: #ffffff;

  --documentSectionBackground: var(--grey7);
  --documentSectionTextColor: var(--textColor);

  --chipBackgroudColor: rgba(255, 255, 255, 0.08);
}

//Global styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /** For testing */
  // background-color: #21538410;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  color: var(--textColor);
  font-family: Roboto, sans-serif;
  background-color: var(--background);
  height: 100%;
}

h2 {
  font-size: 20px;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

textarea {
  border: 1px solid $darkBlue;
  border-radius: $borderRadius;
}

a {
  text-decoration: none;
  color: $lightGrey;
  cursor: pointer;
}

a:hover {
  color: $orange;
}

#root {
  height: 100%;
}

body .Mui-error {
  color: red !important;
}

.firstPage {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $electric;
  width: 100%;
  height: 100%;
  color: #ffffff;

  img {
    max-width: 300px;
  }

  h2 {
    margin: 50px 0 20px 0;
    font-weight: 400;
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  div {
    margin-bottom: 5px;
  }
  a {
    color: #ffffff;

    &:hover {
      text-decoration: underline;
    }
  }
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.container {
  margin: 0 50px;
}

.error {
  font-size: 14px;
  color: $errorColor;
}

.accept {
  color: $green;
}

.submit-error {
  font-size: 14px;
  color: $errorColor;
  text-align: center;
  margin-bottom: 5px;
}

.global__main-title {
  text-align: center;
  color: $darkBlue;
  margin: 30px 0;
}

.global__upload-requirements {
  font-size: 12px;
  margin: 5px 0;
}

.global__capitalizeFirstLetter {
  text-transform: capitalize;
}

.global__emptyList {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.global__onHover:hover {
  color: $orange;
}

.photo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--photoBoxColor);
  border: 1px solid var(--photoBoxColor);
  border-radius: $borderRadius;
  width: 150px;
  height: 200px;
}

// Rewrite date picker styles
body .react-date-picker__wrapper {
  border: 1px solid $darkBlue;
  border-radius: $borderRadius;
  padding: 2px;
  width: 200px;
  background-color: $inputColor;
}

body .react-date-picker__calendar .react-calendar {
  border: 1px solid $darkBlue;
  border-radius: $borderRadius;
}

body .react-calendar__tile--now {
  background: $orange;
  color: #ffffff;
}
body .react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: $orangeDark;
}

body .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: $darkBlue;
}

body .react-calendar__tile--active {
  background: $lightBlue;
}

// End of date picker styles rewrites

//Routes styles
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  h2 {
    color: $electric;
    text-align: center;
    margin-bottom: 50px;
  }
}

.login__box {
  border: 1px solid $grey;
  border-radius: $borderRadius;
  padding: 30px 60px;
  box-shadow: 0 0 5px $grey;
}

.login__box-title {
  h2 {
    font-weight: normal;
  }
}

.login__logo {
  text-align: center;
  margin-bottom: 20px;

  img {
    max-width: 150px;
    max-height: 35px;
  }
}

.login__input {
  margin: 20px 0;
}

.login__input-label {
  text-transform: capitalize;
  margin-bottom: 5px;
}

.login__submit {
  display: flex;
  justify-content: center;
  margin: 10px 0 30px;
}

.login__registration {
  margin: 10px 0;
  text-align: center;
}

.header {
  background-color: $electric;
  margin: 0 -50px;
}

.header__logo {
  width: 80px;
}

.header__box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 50px;
}

.header__switchIcons {
  color: var(--switchIconsColor) !important;
}

.header__links {
  color: #ffffff;
  cursor: pointer;
}

.header__language {
  border: none;
  text-transform: uppercase;
  background-color: var(--headerBackground) !important;
  color: #ffffff;
}

.header__language-icon {
  font-size: 12px;
  margin-right: 5px;
  color: #ffffff;
}

.header__elem {
  padding: 0 25px;
  color: #ffffff;

  a {
    color: unset;
  }
}

.header__logout {
  color: #ffffff;
  cursor: pointer;
}

.vesselCrewForm {
  margin-bottom: 30px;
}

%inputLebels {
  display: block;
  margin-bottom: 5px;
}

.vesselCrewForm__back {
  cursor: pointer;
}

.vesselCrewForm__back:hover {
  color: $lightBlue;
}

.vesselCrewForm__section-title {
  color: $orange;
  font-weight: bold;
  font-size: 18px;
  padding: 5px;
  margin: 50px 0 20px;
  border-bottom: 1px solid $darkBlue;
}

.vesselCrewForm__section-subtitle {
  color: $lightGrey;
  text-align: center;
  margin: 30px 0;
}

.vesselCrewForm__guide {
  color: $lightGrey;
  margin: 0 0 30px 30px;

  li {
    margin-bottom: 10px;
  }
}

.vesselCrewForm__basic {
  display: flex;
  flex-wrap: wrap;
}

.vesselCrewForm__basic-elem {
  min-width: 200px;
  margin: 10px;

  span:first-child {
    @extend %inputLebels;
  }
}

.vesselCrewForm__basic-elem-picker {
  width: 200px;
}

.vesselCrewForm__salary {
  display: flex;
  flex-wrap: wrap;
}

.vesselCrewForm__salary-elem {
  padding: 10px 15px;

  div:first-child {
    @extend %inputLebels;
  }

  div:last-child {
    display: flex;
    align-items: center;
    width: 200px;

    span {
      margin-left: 5px;
    }
  }
}

.vesselCrewForm__educaiton {
  display: flex;
  flex-wrap: wrap;
}

.vesselCrewForm__educaiton-elem {
  width: 200px;
  margin: 10px;

  span:first-child {
    @extend %inputLebels;
  }
}

.vesselCrewForm__educaiton .dropdown__select {
  width: 180px;
}

.vesselCrewForm__document {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin: 20px 0;
}

.vesselCrewForm__document-elem {
  margin: 10px;
  width: 200px;

  span:first-child {
    @extend %inputLebels;
  }
}

.vesselCrewForm__document-elem-upload {
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }
}

.vesselCrewForm__select-box {
  display: flex;
  align-items: center;
  border: 1px solid $darkBlue;
  border-radius: $borderRadius;
  width: 200px;
  background-color: $inputColor;
}

.vesselCrewForm__select {
  padding: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 90%;
  background-color: $inputColor;
}

.vesselCrewForm__select-icon {
  font-size: 12px;
  margin-right: 8px;
}

.vesselCrewForm__document-number,
.vesselCrewForm__document-placeIssue {
  display: block;
  padding: 8px;
  border: 1px solid $darkBlue;
  border-radius: $borderRadius;
  width: 200px;
}

.vesselCrewForm__document-elem-info {
  color: $lightGrey;
}

.vesselCrewForm__document-delete {
  position: relative;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $lightGrey;
  width: 100%;
  margin: 10px 0 30px;
  padding: 10px 0;
}

.vesselCrewForm__document-delete-icon {
  color: $lightGrey;
  cursor: pointer;
}

.vesselCrewForm__document-delete-icon:hover {
  color: $orangeDark;
}

.vesselCrewForm__document-submit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.vesselCrewForm__textarea {
  width: 100%;
  height: 200px;
  background-color: $inputColor;
}

.seaGoing {
  display: flex;
  flex-wrap: wrap;
}

.seaGoing__elem {
  margin: 10px;
  width: 200px;

  span:first-child {
    @extend %inputLebels;
  }
}

.crewingAgencies {
  display: flex;
  flex-wrap: wrap;
}

.crewingAgencies__elem {
  margin: 10px;
  width: 200px;

  span:first-child {
    @extend %inputLebels;
  }
}
.vesselCrewForm__upload-photo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.vesselCrewForm__upload-photo-elem {
  margin: 20px;
  span {
    @extend %inputLebels;
  }
}

.vesselCrewForm__upload-photo-img {
  max-width: 200px;
  max-height: 200px;
}

.vesselCrewForm__upload-document {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vesselCrewForm__upload-document-elem {
  width: 250px;
  margin: 10px;

  span {
    @extend %inputLebels;
  }
}

.vesselCrewForm__upload-document-elem-button {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.vesselCrewForm__upload-document-show {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.vesselCrewForm__upload-document-show-elem {
  margin: 10px;

  span {
    @extend %inputLebels;
    text-align: center;
  }
}

.vesselCrewForm__upload-document-show-delete {
  color: $lightGrey;
  cursor: pointer;
  margin-left: 10px;

  :hover {
    color: $orange;
  }
}

.vesselCrewForm__term {
  margin-top: 40px;

  span {
    color: $darkBlue;
  }
}

.vesselCrewForm__term-agree {
  margin-top: 20px;

  div {
    margin: 5px 0;
  }
}

.vesselCrewForm__submit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;

  span {
    margin: 10px 0;
  }
}

.formConfirm__message {
  text-align: center;
  margin: 30px;
  color: $darkBlue;
  font-size: 1.2em;
}

.userCv {
  margin-bottom: 50px;
}

.userCv__print {
  display: flex;
  justify-content: flex-end;
  color: $darkBlue;
  margin-bottom: 20px;

  span {
    padding: 0 5px;
  }
}

.userCv__showPdf {
  cursor: pointer;
}

.userCv__showPdf:hover {
  color: $orange;
}

.userCv__mainInfo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.userCv__mainInfo-elem {
  margin: 10px;

  img {
    max-width: 250px;
    max-height: 250px;
  }

  div {
    margin: 5px 0;
  }

  span {
    font-weight: bold;
    color: $lightBlue;
  }
}

.userCv__mainInfo-elem-name {
  font-size: 1.1em;
  font-weight: bold;
  color: $darkBlue;
  padding-bottom: 10px;
}

.userCv__headline {
  text-align: center;
  color: $orange;
  font-size: 18px;
  font-weight: bold;
  margin: 40px 0 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid $lightGrey;
}

.userCv__salary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  div {
    margin: 10px 20px;

    span:first-child {
      font-weight: bold;
      color: $lightBlue;
    }
  }
}

.userCv__section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.userCv__section-elem {
  margin: 10px 20px;

  span {
    font-weight: bold;
    color: $lightBlue;
  }
}

.userCv__table {
  width: 100%;
  overflow-x: auto;
  border-collapse: collapse;

  tr:nth-child(even) {
    background-color: #ececec;
  }

  th {
    color: $lightBlue;
    text-align: left;
  }

  td,
  th {
    padding: 5px 5px;
  }
}

.crewAgencyPage__closeDrawer {
  width: 100%;
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    margin: 0 5px;
  }
}

.crewAgencyPage__drawer-title {
  text-align: center;
  font-size: 18px;
  margin: 10px 0;

  div:first-child {
    color: $electric;
  }

  div {
    margin: 5px 0;
  }
}

#crewEmployeeList {
  overflow: auto;
}

.crewEmployeesList__title {
  text-align: center;
  margin: 50px;
  font-size: 20px;
  color: $orange;
}

.crewEmployeesList__filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  align-items: center;
}

.crewEmployeesList__filter-el {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 5px 10px;
}

.crewEmployeesList__filter-inputBox {
  margin: 5px;
  max-width: 300px;
}

.crewEmployeesList__filter-reset {
  color: var(--buttonTransparentTextColor);
  cursor: pointer;
}

.crewEmployeesList__filter-reset:hover {
  color: $orange;
}

.crewEmployeesList__noResult {
  text-align: center;
}

.crewEmployeeView__nameTitle {
  text-align: center;
  margin: 50px;
  font-size: 20px;
  color: $orange;

  div {
    display: inline-block;
  }

  div::first-letter {
    text-transform: uppercase;
  }
}

.crewEmployeeView__selectedContainer {
  margin: 30px 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
}

.crewEmployeeView__basicInfo-el {
  margin: 10px 0;

  span:first-child {
    color: var(--electric);
  }
}

.crewEmployeeView__basicInfo-profilePicture {
  max-width: 400px;
  max-height: 400px;
  margin: 0 10px 10px;
}

.crewEmployeeView__salary,
.crewEmployeeView__education,
.crewEmployeeView__physicalState {
  display: flex;
  flex-wrap: wrap;

  div {
    margin: 10px;

    span:first-child {
      color: var(--electric);
      margin-right: 5px;
    }

    span:last-child {
      margin-left: 5px;
    }
  }
}

.crewEmployeeView__actionsSection {
  margin: 50px 0;
}

.crewEmployeeView__actionsSection-el {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.crewEmployeeView__actionsSection-el-text {
  margin-right: 15px;
}

.crewEmployeesList__addNew {
  margin: 15px 0;
  color: $lightGrey;
  cursor: pointer;
}

.crewEmployeesList__addNew:hover {
  color: $orange;
}

.crewEmployeesList__addNew-icon {
  color: $orange;
}

.crewEmployeeView__notes {
  margin: 50px 0;
}

.crewEmployeeView__notes-cards {
  margin-bottom: 20px;
}

.crewEmployeeView__notes-button {
  margin-top: 20px;
}

.addNewEmployee__title {
  color: $orange;
  font-size: 18px;
  text-align: center;
  margin: 50px 0;
}

.addNewEmployee__stepper-buttons {
  display: flex;
  align-items: center;
  margin: 50px 0 50px;

  div:first-child {
    color: var(--buttonTransparentTextColor);
    cursor: pointer;
    margin-right: 10px;
  }

  div:first-child:hover {
    color: $orange;
  }
}

.addNewEmployee__addRemove {
  cursor: pointer;
  color: $lightGrey;
  margin-top: 10px;
  width: fit-content;

  .icon {
    color: $orange;
    margin-right: 5px;
  }
}

.addNewEmployee__addRemove:hover {
  color: $orange;
}

.addNewEmployee__upload-msg {
  margin: 5px;
  font-size: 14px;
  color: $green;
}

.addNewEmployee__addCompanyButton {
  margin-top: 30px;
}

// Material UI override styles

.inputPadding input {
  background-color: var(--inputBackground);
  border-radius: $borderRadius;
  padding: 8px;
  color: var(--inputTextColor);
}

.inputPadding label {
  transform: translate(14px, 10px) scale(1);
}

.autocomplete {
  background-color: $inputColor;
}

.registration {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  padding: 30px;
}

.registration__box {
  display: flex;
  flex-wrap: wrap-reverse;
  border: 1px solid $grey;
  border-radius: $borderRadius;
  box-shadow: 0 0 5px $grey;
  width: fit-content;
}

.registration__form {
  width: 50%;
  padding: 30px;
  max-width: 600px;
  min-width: 300px;

  .registration__logo {
    text-align: center;
    margin-bottom: 20px;

    img {
      max-width: 150px;
      max-height: 35px;
    }
  }
}

.registration__inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  h2 {
    text-align: center;
    color: $electric;
    padding-bottom: 20px;
    font-weight: normal;
  }
}

.registration_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  color: #ffffff;
  background-color: $lightBlue;
  padding: 30px;
  max-width: 600px;
  min-width: 300px;

  h2 {
    margin-bottom: 30px;
  }
}

.terms {
  margin-bottom: 50px;

  h2 {
    margin: 50px 0;
    color: $electric;
  }
  h3 {
    margin: 40px 0 20px;
    color: $darkBlue;
  }
  p {
    margin: 10px 0;
  }
  ol {
    counter-reset: section;
    list-style-type: none;

    li:before {
      counter-increment: section;
      content: counters(section, '.') '. ';
    }
  }
  li {
    margin: 0 0 5px 30px;
  }
}

.terms__company {
  margin-top: 50px;
  text-align: center;
}

.resetPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;

  h2 {
    color: $electric;
    text-align: center;
    margin-bottom: 30px;
  }
}

.resetPassword__box {
  border: 1px solid $grey;
  border-radius: $borderRadius;
  box-shadow: 0 0 5px $grey;
  padding: 30px 60px;
}

.resetPassword__button {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.addNewKeyEmployee__list-title {
  margin-bottom: 15px;
}

.addNewKeyEmployee__title {
  display: flex;
  align-items: center;
  height: 100%;
}

.keyEmployeeView__personalSkills {
  margin-top: 30px;
}

.keyEmployeeView__personalSkills-block {
  margin-top: 50px;

  :last-child {
    margin-bottom: 50px;
  }
}

.gprsPolicy {
  margin: 50px 0;
  h3 {
    margin-bottom: 20px;
    color: $electric;
  }
}

.employeePage__snackbar-link {
  margin-top: 10px;
  color: $lightBlue;
  cursor: pointer;
}

.employeePage__snackbar-link:hover {
  color: $orange;
}

.editUser__tabSection {
  margin-bottom: 50px;
}

.editEmployee__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .editEmployee__buttons-button {
    margin: 0 10px;
  }
}

.savingDataMsg__message {
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    margin: 10px 0;
  }
  div:first-child {
    height: 40px;
    width: 40px;
  }
}

.wrapper {
  flex: 1 0 auto;
}

.addNewCompany__title {
  font-size: 18px;
  margin-top: 30px;
}

.companyPage__deleteCompany {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: $errorColor;
  font-size: 12px;

  span:hover {
    cursor: pointer;
  }
}

.getProjectsEmployeesList__editButton {
  cursor: pointer;
  :hover {
    color: $orange;
  }
}

.getProjectsEmployeesList__link {
  cursor: pointer;
  :hover {
    color: $orange;
  }
}

.getProjectsEmployeesList__link:hover {
  color: $orange;
}

.versionsLog {
  margin-top: 50px;

  h3 {
    color: $electric;
    margin: 20px 0;
  }

  li {
    margin-left: 30px;
  }
}

.smartPlanning__calendar-block {
  display: inline-block;
}

.smartPlanning__calendar-block--hideContet {
  display: none;
}

.smartPlanning__calendar-block--yellow {
  background-color: $yellow;
  z-index: 1;
  opacity: 0.8;
  cursor: pointer;
  &:hover {
    min-width: 261px;
    z-index: 2;
    opacity: 1;
    .smartPlanning__calendar-block--hideContet {
      display: block;
    }
  }
}

.smartPlanning__calendar-block--green {
  background-color: $green;
  z-index: 1;
  opacity: 0.8;
  cursor: pointer;
  &:hover {
    min-width: 261px;
    z-index: 2;
    opacity: 1;
    .smartPlanning__calendar-block--hideContet {
      display: block;
    }
  }
}

.smartPlanning__calendar-block--orange {
  background-color: $orange;
  z-index: 1;
  opacity: 0.8;
  cursor: pointer;
  &:hover {
    min-width: 261px;
    z-index: 2;
    opacity: 1;
    .smartPlanning__calendar-block--hideContet {
      display: block;
    }
  }
}

.smartPlanning__calendar-year {
  text-align: center;
  border: 1px solid $grey;
  border-bottom: none;
  // border-left: none;
  border-right: 2px solid $grey;
  margin-left: -2px;
  background-color: $lightBlue;
  color: #ffffff;
  padding: 5px 0;
}

.smartPlanning__calendar-month {
  display: inline-block;
  text-align: center;
  border: 1px solid $grey;
  margin-left: -2px;
  background-color: $darkBlue;
  color: #ffffff;
}

.smartPlanning__calendar-day {
  position: relative;
  display: inline-block;
  font-size: 12px;
  width: 5px;
  height: 100%;
  padding: 3px 0;
  text-align: center;
  margin-left: -1px;
}

.smartPlanning__calendar-day--oneMonth {
  @extend .smartPlanning__calendar-day;
  border: 1px solid $grey;
  border-left: none;
  border-bottom: 1px solid $grey;
  width: 40px;
}

.smartPlanning__calendar-day--threeMonths {
  @extend .smartPlanning__calendar-day;
  width: 15px;
}

.smartPlanning__calendar-day--sixMonths {
  @extend .smartPlanning__calendar-day;
  width: 7px;
}

.smartPlanning__calendar-row {
  border-bottom: 1px solid $grey;
  border-right: 1px solid $grey;
  width: fit-content;
}

.MuiTableCell-body {
  color: var(--textColor) !important;
}
.MuiTableCell-root {
  color: var(--textColor) !important;
}

.MuiTableCell-stickyHeader {
  color: var(--tableStickyHeaderTextColor) !important;
}

.MuiPaper-root {
  background-color: var(--paperBackground) !important;
  color: var(--textColor) !important;
}

#dashboard {
  .MuiList-root {
    .MuiTypography-root {
      color: var(--textColor);
    }
  }
}

.MuiCardHeader-root {
  background-color: var(--cardHeaderBackground) !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  border-bottom: 2px solid var(--cardHeaderBorderBottom) !important;
  .MuiTypography-root {
    color: var(--cardHeaderTitle) !important;
  }
}

.MuiCardActions-root {
  .MuiIconButton-root {
    color: var(--textColor) !important;
  }
}

.MuiTablePagination-root,
.MuiMenu-list {
  color: var(--textColor) !important;
}

.MuiOutlinedInput-inputMarginDense,
.MuiOutlinedInput-root,
.MuiOutlinedInput-input {
  background-color: var(--inputBackground) !important;
  color: var(--textColor) !important;
}

.MuiOutlinedInput-root .MuiSvgIcon-root {
  color: var(--textColor) !important;
}

.MuiOutlinedInput-root {
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px var(--inputBackground) inset;
    box-shadow: 0 0 0 100px var(--inputBackground) inset;
    -webkit-text-fill-color: var(--textColor);
  }
}
.MuiOutlinedInput-inputMultiline {
  color: var(--textColor) !important;
}

input[type='date' i]::-webkit-calendar-picker-indicator,
input[type='time' i]::-webkit-calendar-picker-indicator {
  filter: var(--datePickerIconColor);
}

.MuiFormLabel-root,
.MuiFormLabel-root.Mui-focused {
  color: var(--inputLabelColor) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--inputBorderColor) !important;
}

.MuiFormHelperText-root {
  color: var(--helperTextColor) !important;
}

.MuiSelect-root,
.MuiSelect-icon {
  color: var(--textColor) !important;
}

.MuiAccordion-root {
  border-color: var(--accordionBorderColor) !important;
  .MuiIconButton-root {
    color: var(--textColor) !important;
  }
}

.MuiStepLabel-label {
  color: var(--stepperLabelTextColor) !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: var(--stepperLabelActiveTextColor) !important;
}

.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--stepperIconBackgroundColor) !important;
}

.MuiTab-root {
  color: var(--tabTextColor) !important;
}

.MuiTypography-colorTextSecondary {
  color: var(--typographyColorTextSecondary) !important;
}

.MuiChip-outlined {
  border-color: var(--chipOutlineBorderColor) !important;
  color: var(--textColor) !important;
}

.MuiButton-root {
  color: unset !important;
}

.MuiDialogTitle-root {
  color: var(--dialogHeaderTextColor) !important;
  background-color: var(--dialogHeaderBackgroundColor) !important;
}

.MuiDialogActions-root {
  .MuiButton-contained {
    color: #ffffff !important;
  }
}

.plannedStyles {
  .MuiChip-outlined {
    color: var(--statusPlanned) !important;
    border-color: var(--statusPlanned) !important;
  }
}

.availableStyles {
  .MuiChip-outlined {
    color: var(--statusAvailable) !important;
    border-color: var(--statusAvailable) !important;
  }
}

.atHomeStyles {
  .MuiChip-outlined {
    color: var(--statusAtHome) !important;
    border-color: var(--statusAtHome) !important;
  }
}

.atWorkStyles {
  .MuiChip-outlined {
    color: var(--statusAtWork) !important;
    border-color: var(--statusAtWork) !important;
  }
}

.medicineStyles {
  .MuiChip-outlined {
    color: var(--statusMedicine) !important;
    border-color: var(--statusMedicine) !important;
  }
}

.coursesStyles {
  .MuiChip-outlined {
    color: var(--statusCourses) !important;
    border-color: var(--statusCourses) !important;
  }
}

.recharts-text {
  fill: var(--rechartTextColor) !important;
}

.MuiAppBar-root {
  background-color: var(--appBarBackgroundColor) !important;
}

.documentsSection {
  background-color: var(--documentSectionBackground) !important;
  text-align: center !important;
  color: var(--documentSectionTextColor) !important;
}

.MuiChip-root {
  color: var(--inputTextColor) !important;
  background-color: var(--chipBackgroudColor) !important;
}

.MuiListItemIcon-root {
  color: var(--inputTextColor) !important;
}

// Media queries

@media only screen and (max-width: 780px) {
  .registration__box {
    .registration__form {
      width: 100%;
    }
    .registration_text {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .header {
    justify-content: center;
  }

  .header__elem {
    padding: 10px;
  }
}

@media only screen and (max-width: 339px) {
  .choose__buttons {
    justify-content: center;
  }
}
