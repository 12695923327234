@import '../index.scss';

.generatedPdf {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 1000;
}

.generatedPdf__background {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: -1;
}

.generatedPdf__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.generatedPdf__close {
    display: flex;
    justify-content: flex-end;
    background-color: #ffffff;
    width: 90%;
    padding: 10px;

    span {
        cursor: pointer;
    }
    span:hover {
        color: $orange;
    }
}